// src/pages/merch-pro/project-management.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectManagement = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  // Fetch the projects from the server when the component mounts
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-image-projects`, { credentials: 'include' });
        const data = await response.json();
        setProjects(data.projects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  // Handle the click on a project to navigate to the ImageCreation component
  const handleProjectClick = (requestId) => {
    navigate(`/image-creation/${requestId}`);
  };

  return (
    <div className="container mt-4">
      <h2>My Projects</h2>
      {projects.length === 0 ? (
        <p>You have no saved projects.</p>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Request ID</th>
              <th>Prompt</th>
              <th>Width</th>
              <th>Height</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.request_id}>
                <td>{project.project_name || 'Untitled Project'}</td>
                <td>{project.request_id}</td>
                <td>{project.prompt}</td>
                <td>{project.width}</td>
                <td>{project.height}</td>
                <td>{new Date(project.created_at).toLocaleString()}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleProjectClick(project.request_id)}
                  >
                    Open
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProjectManagement;
