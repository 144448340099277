import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

const RequestQueueTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRequestQueue();
  }, []);

  // Function to fetch the prompt queue data
  const fetchRequestQueue = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-prompt-queue`, { credentials: 'include' });
      if (response.ok) {
        const queueData = await response.json();
        // Sort by latest request first
        const sortedData = queueData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setData(sortedData);
      } else {
        console.error('Failed to fetch the prompt queue.');
      }
    } catch (error) {
      console.error('Error fetching prompt queue:', error);
    } finally {
      setLoading(false);
    }
  };

// Function to clear an individual request
const handleClearRequest = async (promptQueueId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clear-request/${promptQueueId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
        if (response.ok) {
            setData(data.filter(item => item.promptQueueId !== promptQueueId));
        } else {
            console.error('Failed to clear request.');
        }
    } catch (error) {
        console.error('Error clearing request:', error);
    }
};


  // Function to clear all requests
  const handleClearAllRequests = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clear-all-requests`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        setData([]);
      } else {
        console.error('Failed to clear all requests.');
      }
    } catch (error) {
      console.error('Error clearing all requests:', error);
    }
  };

  // Define the columns with proper function binding
  const columns = [
    { name: 'Prompt', selector: row => row.prompt, sortable: true },
    { name: 'Queue ID', selector: row => row.promptQueueId, sortable: true }, // Display the promptQueueId
    { name: 'Request ID', selector: row => row.requestId, sortable: true }, // Display the requestId
    { name: 'Status', selector: row => row.status, sortable: true },
    { name: 'Date Created', selector: row => new Date(row.createdAt).toLocaleString(), sortable: true },
    {
      name: 'Actions',
      cell: row => (
        <button onClick={() => handleClearRequest(row.promptQueueId)} className="btn btn-danger btn-sm">
          Clear
        </button>
      ),
    },
  ];

  return (
    <div>
      <h2>Request Queue</h2>
      <button onClick={handleClearAllRequests} className="btn btn-danger mb-3">
        Clear All Requests
      </button>
      <DataTable
        title="Image Request Queue"
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
      />
    </div>
  );
};

export default RequestQueueTable;
