// src/pages/merch-pro/WelcomePage.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { AppSettings } from './../../config/app-settings.js';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './WelcomePage.css';

function WelcomePage() {
  const context = React.useContext(AppSettings);

  useEffect(() => {
    context.handleSetAppContentFullHeight(true);
    context.handleSetAppContentClass('p-0 d-flex flex-column');

    return () => {
      context.handleSetAppContentFullHeight(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="h-100 d-flex flex-column">
      <PerfectScrollbar
        className="app-content-padding flex-grow-1 overflow-hidden"
        options={{ suppressScrollX: true }}
      >
        <ol className="breadcrumb float-xl-end">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Welcome</li>
        </ol>
        <h1 className="page-header">
          Welcome to MerchPro <small>Your image project hub.</small>
        </h1>
        {/* Wrapper div with margin */}
        <div className="welcome-panel-wrapper">
          <Panel>
            <PanelHeader>Ready, Set... Build!</PanelHeader>
            <PanelBody>
              <div className="welcome-panels-container">
                {/* Create New Image Project Panel */}
                <Link to="/image-creation" className="welcome-sub-panel-link">
                  <Panel className="welcome-sub-panel">
                    <div className="panel-content">
                      <img
                        src="/assets/img/welcome-page/create_new_project.png"
                        alt="Create New Project"
                        className="panel-image"
                      />
                      <h3>Create New Image Project</h3>
                      <p>Start a fresh project and bring your ideas to life.</p>
                    </div>
                  </Panel>
                </Link>

                {/* Continue Working on a Project Panel */}
                <Link to="/projects" className="welcome-sub-panel-link">
                  <Panel className="welcome-sub-panel">
                    <div className="panel-content">
                      <img
                        src="/assets/img/welcome-page/continue_project.png"
                        alt="Continue Project"
                        className="panel-image"
                      />
                      <h3>Continue Working on a Project</h3>
                      <p>Access your existing projects and make updates.</p>
                    </div>
                  </Panel>
                </Link>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </PerfectScrollbar>

      <div id="footer" className="app-footer m-0">
        &copy; {new Date().getFullYear()} MerchPro All Rights Reserved
      </div>
    </div>
  );
}

export default WelcomePage;
