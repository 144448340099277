import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function MidjourneyVerification({ children }) {
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/midjourney_verify_status`, {
          method: 'GET',
          credentials: 'include',
        });
        const result = await response.json();

        if (response.ok) {
          setStatus(result.status);
          if (result.status === 'missing_credentials') {
            navigate('/midjourney-registration');
          } else if (result.status === 'subscription_expired') {
            navigate('/subscription-renewal'); // Redirect to subscription renewal page if needed
          }
        } else {
          console.error('Error checking Midjourney status:', result);
        }
      } catch (error) {
        console.error('Error verifying Midjourney status:', error);
      }
    };

    verifyStatus();
  }, [navigate]);

  // Show a loading indicator or placeholder until status is checked
  if (!status || status !== 'ready') {
    return <p>Loading Midjourney status...</p>;
  }

  // If status is ready, render the children components
  return <>{children}</>;
}

export default MidjourneyVerification;
