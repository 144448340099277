import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function MpLogout() {
  useEffect(() => {
    // Send request to backend to log out the user
    fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
      method: 'POST', // Make sure your Flask backend accepts POST requests for logout
      credentials: 'include', // Include cookies for Flask-Login
    })
    .then(response => response.json())
    .then(data => {
      console.log('Logout successful:', data);
    })
    .catch(error => {
      console.error('Error during logout:', error);
    });
  }, []);

  // Redirect to the login page after logging out
  return <Navigate to="/login" />;
}

export default MpLogout;
