import React, { useEffect, useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

function MpRegister() {
  const context = useContext(AppSettings);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    discount: '',
    acceptTos: false,
  });

  const [redirect, setRedirect] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      console.log("API Base URL:", process.env.REACT_APP_API_BASE_URL);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.status === 201) {
        setRedirect(true);  // Successful registration
      } else if (response.status === 409) {
        // Email already registered, redirect to login
        alert("Email already registered. Redirecting to login...");
        setRedirectToLogin(true);
      } else {
        console.error('Registration failed');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  };

  if (redirect) {
    return <Navigate to='/dashboard/v3' />;
  }

  if (redirectToLogin) {
    return <Navigate to='/login' />;
  }

  return (
    <div className="register register-with-news-feed">
      <div className="news-feed">
        <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/image_2024.png)' }}></div>
        <div className="news-caption">
          <h4 className="caption-title"><b>Merch</b> Pro</h4>
          <p>Let's build something amazing together.</p>
        </div>
      </div>
      <div className="register-container">
        <div className="register-header mb-25px h1">
          <div className="mb-1">Sign Up</div>
          <small className="d-block fs-15px lh-16">Create your MerchPro Account.</small>
        </div>
        <div className="register-content">
          <form onSubmit={handleSubmit} className="fs-13px">
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div className="mb-3">
              <label className="mb-2">Name <span className="text-danger">*</span></label>
              <div className="row gx-3">
                <div className="col-md-6 mb-2 mb-md-0">
                  <input type="text" className="form-control fs-13px" placeholder="First name" name="firstName"
                         value={formData.firstName} onChange={handleChange} required/>
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control fs-13px" placeholder="Last name" name="lastName"
                         value={formData.lastName} onChange={handleChange} required/>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="mb-2">Email <span className="text-danger">*</span></label>
              <input type="email" className="form-control fs-13px" placeholder="Email address" name="email"
                     value={formData.email} onChange={handleChange} required/>
            </div>
            <div className="mb-4">
              <label className="mb-2">Password <span className="text-danger">*</span></label>
              <input type="password" className="form-control fs-13px" placeholder="Password" name="password"
                     value={formData.password} onChange={handleChange} required/>
            </div>
            <div className="mb-4">
              <label className="mb-2">Retype Password <span className="text-danger">*</span></label>
              <input type="password" className="form-control fs-13px" placeholder="Retype Password"
                     name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required/>
            </div>
            <div className="mb-4">
              <label className="mb-2">Phone <span className="text-danger">*</span></label>
              <input type="tel" className="form-control fs-13px" placeholder="Phone" name="phone" value={formData.phone}
                     onChange={handleChange} required/>
            </div>
            <div className="mb-4">
              <label className="mb-2">Discount </label>
              <input type="text" className="form-control fs-13px" placeholder="Discount Code" name="discount" value={formData.discount}
                     onChange={handleChange} />
            </div>
            <div className="form-check mb-4">
              <input className="form-check-input" type="checkbox" name="acceptTos" id="agreementCheckbox"
                     checked={formData.acceptTos} onChange={handleChange} required/>
              <label className="form-check-label" htmlFor="agreementCheckbox">
                By clicking Sign Up, you agree to our <Link to="/user/register-v3">Terms</Link> and that you have read
                our <Link to="/user/register-v3">Data Policy</Link>, including our <Link to="/user/register-v3">Cookie
                Use</Link>.
              </label>
            </div>
            <div className="mb-4">
              <button type="submit" className="btn btn-theme d-block w-100 btn-lg h-45px fs-13px">Sign Up</button>
            </div>
            <div className="mb-4 pb-5">
              Already a member? Click <Link to="/login">here</Link> to login.
            </div>
            <hr className="bg-gray-600 opacity-2"/>
            <p className="text-center text-gray-600">
              &copy; MerchPro All Right Reserved 2024
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MpRegister;
