import React, { useEffect, useContext, useState } from 'react';
import { Navigate, Link } from 'react-router-dom'; // Import Link for navigation
import { AppSettings } from '../../config/app-settings.js';
import {login} from "../../api"; // Ensure this points to the correct context

function MpLogin() {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log(context);
    // Hide the navigation bars and other UI elements for the login page
    context.handleSetAppHeaderNone(true);
    context.handleSetAppSidebarNone(true);
    context.handleSetAppContentClass('p-0');

    // Cleanup on unmount: revert the UI state back to default
    return () => {
      context.handleSetAppHeaderNone(false);
      context.handleSetAppSidebarNone(false);
      context.handleSetAppContentClass('');
    };
  }, [context]);

  async function handleSubmit(event) {
    event.preventDefault();

    const email = document.getElementById('emailAddress').value;
    const password = document.getElementById('password').value;

    try {
      const response = await login(email, password);

      if (response.ok) {
        const result = await response.json();
        console.log('Context:', context);

        context.setUser(result.customer);
        setRedirect(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error || 'Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred during login.');
    }
  }

  if (redirect) {
    return <Navigate to='/welcome' />;
  }

  return (
    <div className="login login-v1">
      <div className="login-container">
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span> <b>Merch</b> Pro
            </div>
            <small>Let's <b>Build</b> something together.</small>
          </div>
          <div className="icon">
            <i className="fa fa-lock"></i>
          </div>
        </div>
        <div className="login-body">
          <div className="login-content fs-13px">
            <form onSubmit={handleSubmit}>
              <div className="form-floating mb-20px">
                <input type="email" className="form-control fs-13px h-45px" id="emailAddress" placeholder="Email Address" />
                <label htmlFor="emailAddress" className="d-flex align-items-center py-0">Email Address</label>
              </div>
              <div className="form-floating mb-20px">
                <input type="password" className="form-control fs-13px h-45px" id="password" placeholder="Password" />
                <label htmlFor="password" className="d-flex align-items-center py-0">Password</label>
              </div>
              <div className="form-check mb-20px">
                <input className="form-check-input" type="checkbox" value="" id="rememberMe" />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div>
              {errorMessage && (
                <div className="alert alert-danger">
                  {errorMessage}
                </div>
              )}
              <div className="login-buttons">
                <button type="submit" className="btn h-45px btn-theme d-block w-100 btn-lg">Sign me in</button>
              </div>
            </form>
            <div className="mt-3 text-center">
              <span>Don't have an account? </span>
              <Link to="/register" className="text-decoration-none">Register here</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MpLogin;
