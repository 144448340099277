import React, { useEffect, useState, useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

function MpMidjourneyRegistration() {
  const context = useContext(AppSettings);

  const [formData, setFormData] = useState({
    midjourneyInfo: '',
    midjourneyApi: '',
  });

  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    context.handleSetAppSidebarNone(true);
    context.handleSetAppHeaderNone(true);
    context.handleSetAppContentClass('p-0');

    return () => {
      context.handleSetAppSidebarNone(false);
      context.handleSetAppHeaderNone(false);
      context.handleSetAppContentClass('');
    };
  }, [context]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.midjourneyInfo || !formData.midjourneyApi) {
      setErrorMessage("All fields are required. Check the steps on the page to gather the correct information.");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/midjourney-registration`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        credentials: 'include', // Include credentials if required for authentication
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setRedirect(true);
      } else {
        const result = await response.json();
        setErrorMessage(result.error || "Registration failed");
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      setErrorMessage('An error occurred during registration. Check your entries and try again.');
    }
  };

  if (redirect) {
    return <Navigate to='/welcome' />;
  }

  return (
    <div className="register register-with-news-feed">
      <div className="news-feed">
        <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/mj_registration_bg_1.png)' }}></div>
        <div className="news-caption">
          <h4 className="caption-title"><b>Merch</b> Pro</h4>
          <p>Let's build something amazing together.</p>
        </div>
      </div>
      <div className="register-container">
        <div className="register-header mb-25px h1">
          <div className="mb-1">Midjourney Registration</div>
          <small className="d-block fs-15px lh-16">Setup Midjourney Registration.</small>
        </div>
        <div className="register-content">
          <form onSubmit={handleSubmit} className="fs-13px">
            <div className="mb-3">
              <label className="mb-2">Midjourney Info <span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control fs-13px"
                placeholder="Discord Midjourney Info"
                name="midjourneyInfo"
                value={formData.midjourneyInfo}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="mb-2">Midjourney Api Key <span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control fs-13px"
                placeholder="Discord Midjourney API Key"
                name="midjourneyApi"
                value={formData.midjourneyApi}
                onChange={handleChange}
              />
            </div>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div className="form-check mb-4">
              <input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
              <label className="form-check-label" htmlFor="agreementCheckbox">
                By clicking Sign Up, you agree to our <Link to="/user/register-v3">Terms</Link> and that you have read
                our <Link to="/user/register-v3">Data Policy</Link>, including our <Link to="/user/register-v3">Cookie Use</Link>.
              </label>
            </div>
            <div className="mb-4">
              <button type="submit" className="btn btn-theme d-block w-100 btn-lg h-45px fs-13px">Save</button>
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <p className="text-center text-gray-600">&copy; MerchPro All Right Reserved 2024</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MpMidjourneyRegistration;
