import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';

const PrivateRoute = ({ children }) => {
  const context = useContext(AppSettings);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // To prevent repeated calls, use a flag to check if verification has been done
    let isMounted = true; // This flag will help prevent state updates if the component is unmounted

    const verifyLogin = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verify_login`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok && isMounted) {
          const data = await response.json();
          context.setUser(data.user);
          setIsAuthenticated(true);
        } else if (isMounted) {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error verifying login:', error);
        if (isMounted) {
          setIsAuthenticated(false);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    verifyLogin();

    // Cleanup function to prevent state updates if the component unmounts during the async call
    return () => {
      isMounted = false;
    };
  }, []); // Removed dependencies to ensure it runs only once on mount

  // Display loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render content or redirect
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
