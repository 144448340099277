import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import MidjourneyVerification from './midjourney-verification';
import PerfectScrollbar from 'react-perfect-scrollbar';

function ImageCreation() {
  const [promptFormData, setPromptFormData] = useState({
    prompt: '',
    height: '',
    width: '',
  });
  const [projectName, setProjectName] = useState('');
  const [requestId, setRequestId] = useState(null);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generatingRequestId, setGeneratingRequestId] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [imageDisplayHeight, setImageDisplayHeight] = useState(400); // Default max height
  const [scalerMethod, setScalerMethod] = useState('opencv_fsrcnn'); // Default scaling method
  const [mirrorAll, setMirrorAll] = useState(false);
  const [rotateAll, setRotateAll] = useState(false);

  // New States for Resolution Editing
  const [editingResolutionImageId, setEditingResolutionImageId] = useState(null);
  const [tempResolution, setTempResolution] = useState({ width: '', height: '' });
  const [resolutionSuggestions, setResolutionSuggestions] = useState([]);
  const resolutionEditRef = useRef(null);

  const currentYear = new Date().getFullYear();

  const { request_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (requestId) {
      fetchImages(requestId);
    }
  }, [requestId]);

  useEffect(() => {
    if (request_id) {
      setRequestId(request_id);
      fetchProjectData(request_id);
    } else {
      generateNewRequestId();
    }
  }, [request_id]);

  // Inside ImageCreation component

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resolutionEditRef.current && !resolutionEditRef.current.contains(event.target)) {
        setEditingResolutionImageId(null);
        setTempResolution({ width: '', height: '' });
        setResolutionSuggestions([]);
      }
    };

    if (editingResolutionImageId) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingResolutionImageId]);

  const fetchProjectData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-project-by-request/${id}`, {
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        const project = data.project;
        setPromptFormData({
          prompt: project.prompt,
          height: project.height,
          width: project.width,
        });
        setImageDisplayHeight(project.image_display_height);
        setProjectName(project.project_name);
        setRequestId(id);
        fetchImages(id); // Fetch all images for the project
      } else {
        console.error('Failed to fetch project data.');
      }
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  const handleToggleAllImages = async (action, state) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/toggle-all-images/${requestId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action, state }),
        credentials: 'include',
      });

      if (response.ok) {
        setGeneratedImages((prevImages) =>
          prevImages.map((img) => ({
            ...img,
            isMirrored: action === 'mirror' ? state : img.isMirrored,
            isFlipped: action === 'flip' ? state : img.isFlipped,
          }))
        );
      } else {
        const errorData = await response.json();
        alert('Error toggling images: ' + errorData.error);
      }
    } catch (error) {
      console.error('Error toggling all images:', error);
    }
  };

  const toggleMirrorAll = () => {
    const newMirrorState = !mirrorAll;
    setMirrorAll(newMirrorState);
    handleToggleAllImages('mirror', newMirrorState);
  };

  const toggleRotateAll = () => {
    const newRotateState = !rotateAll;
    setRotateAll(newRotateState);
    handleToggleAllImages('flip', newRotateState);
  };

  const generateNewRequestId = async () => {
    setGeneratingRequestId(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-request-id`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setRequestId(data.requestId);
        setGeneratedImages([]); // Clear images when generating a new request ID
        return data.requestId;
      } else {
        alert('Failed to generate a new project ID.');
      }
    } catch (error) {
      console.error('Error generating request ID:', error);
    } finally {
      setGeneratingRequestId(false);
    }
    return null;
  };

  const fetchImages = async (id) => {
    try {
      const imagesResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-images-by-request/${id}`, {
        cache: 'no-store',
        credentials: 'include',
      });
      if (imagesResponse.ok) {
        const imagesData = await imagesResponse.json();
        const allMirrored = imagesData.imageUrls.every((image) => image.is_mirrored);
        const allFlipped = imagesData.imageUrls.every((image) => image.is_flipped);

        setMirrorAll(allMirrored);
        setRotateAll(allFlipped);

        setGeneratedImages(
          imagesData.imageUrls.map((image) => ({
            url: image.url,
            id: image.id,
            isMirrored: image.is_mirrored,
            isFlipped: image.is_flipped,
            width: image.width,
            height: image.height,
            aspectRatio: image.aspect_ratio, // e.g., "16:9"
          }))
        );
        console.log('Generated Images:', imagesData.imageUrls);
      } else {
        console.error('Error fetching images:', await imagesResponse.text());
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  // Helper function to calculate nearest resolutions
  const getNearestResolutions = (dimension, isWidth, aspectRatioStr) => {
    const [aspectWidth, aspectHeight] = aspectRatioStr.split(':').map(Number);
    const aspectRatio = aspectWidth / aspectHeight;

    let baseDimension = isWidth ? aspectWidth : aspectHeight;
    let multiple = Math.floor(dimension / baseDimension);
    const lowerMultiple = multiple;
    const upperMultiple = multiple + 1;

    const lowerResolution = isWidth
      ? { width: lowerMultiple * aspectWidth, height: lowerMultiple * aspectHeight }
      : { width: lowerMultiple * aspectWidth, height: lowerMultiple * aspectHeight };

    const upperResolution = isWidth
      ? { width: upperMultiple * aspectWidth, height: upperMultiple * aspectHeight }
      : { width: upperMultiple * aspectWidth, height: upperMultiple * aspectHeight };

    return { lowerResolution, upperResolution };
  };

  const handleNewProject = async () => {
    setPromptFormData((prev) => ({
      ...prev,
      prompt: '',
    }));
    setGeneratedImages([]);

    const newRequestId = await generateNewRequestId();

    if (newRequestId) {
      navigate(`/image-creation/${newRequestId}`, {
        state: {
          height: promptFormData.height,
          width: promptFormData.width,
          imageDisplayHeight: imageDisplayHeight,
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading || generatingRequestId) return;

    if (!requestId) {
      alert('Error: Request ID not set.');
      return;
    }

    if (!promptFormData.prompt || !promptFormData.width || !promptFormData.height) {
      alert('Please fill in the prompt, width, and height fields.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/queue-image-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...promptFormData, request_id: requestId }),
        credentials: 'include',
      });

      if (response.ok) {
        const saveProjectResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/save-project`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            request_id: requestId,
            prompt: promptFormData.prompt,
            width: promptFormData.width,
            height: promptFormData.height,
            image_display_height: imageDisplayHeight,
            project_name: projectName || 'Untitled Project',
          }),
          credentials: 'include',
        });

        if (saveProjectResponse.ok) {
          checkImageStatus(requestId);
        } else {
          const errorData = await saveProjectResponse.json();
          alert('Error saving project: ' + errorData.error);
        }
      } else {
        const errorData = await response.json();
        alert(errorData.error);
      }
    } catch (error) {
      alert('Network error.');
    } finally {
      setLoading(false);
    }
  };

  const checkImageStatus = async (id) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/check-image-status/${id}`, {
          credentials: 'include',
        });
        const data = await response.json();

        if (data.status === 'ready') {
          fetchImages(id);
          clearInterval(interval);
        } else if (data.status === 'pending' || data.status === 'request_sent') {
          console.log('Waiting for images to be ready...');
        } else {
          clearInterval(interval);
        }
      } catch (error) {
        clearInterval(interval);
      }
    }, 5000);
  };

  const handleDownloadSingleImage = async (imageId) => {
    if (!imageId) {
      alert('Invalid image ID.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download-image/${imageId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ scalerMethod }),
        credentials: 'include',
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `image_${imageId}.png`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        const errorData = await response.json();
        alert('Error downloading image: ' + errorData.error);
      }
    } catch (error) {
      alert('Network error while downloading image.');
    }
  };

  const handleDownloadAllImages = async (event) => {
    event.preventDefault();
    setDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/download-all-images/${requestId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ scalerMethod }),
        credentials: 'include',
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${requestId}_images_upscale_${scalerMethod}.zip`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        const errorData = await response.json();
        alert('Error downloading images: ' + errorData.error);
      }
    } catch (error) {
      alert('Network error while downloading images.');
    } finally {
      setDownloading(false);
    }
  };

  const toggleMirror = async (imageId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/toggle-mirror/${imageId}`, {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      setGeneratedImages((prevImages) =>
        prevImages.map((img) => (img.id === imageId ? { ...img, isMirrored: data.is_mirrored } : img))
      );
    } catch (error) {
      console.error('Error toggling mirror:', error);
    }
  };

  const toggleFlip = async (imageId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/toggle-flip/${imageId}`, {
        method: 'POST',
        credentials: 'include',
      });
      const data = await response.json();
      setGeneratedImages((prevImages) =>
        prevImages.map((img) => (img.id === imageId ? { ...img, isFlipped: data.is_flipped } : img))
      );
    } catch (error) {
      console.error('Error toggling flip:', error);
    }
  };

  const handleScalerChange = (e) => {
    setScalerMethod(e.target.value);
  };

  // Handler for Resolution Button Click
  const handleResolutionClick = (image) => {
    setEditingResolutionImageId(image.id);
    setTempResolution({ width: image.width, height: image.height });
    setResolutionSuggestions([]);
  };

// Handler for Resolution Input Change
const handleResolutionInputChange = (e, isWidth) => {
  const { name, value } = e.target;
  setTempResolution((prev) => ({
    ...prev,
    [name]: value,
  }));

  // Find the image being edited
  const image = generatedImages.find((img) => img.id === editingResolutionImageId);
  if (!image) return;

  const aspectRatioStr = image.aspectRatio; // e.g., "16:9"
  if (!aspectRatioStr) return;

  const [aspectWidth, aspectHeight] = aspectRatioStr.split(':').map(Number);
  if (isWidth) {
    const inputWidth = parseInt(value);
    if (isNaN(inputWidth) || inputWidth < 1) { // Ensure width is at least 1
      setResolutionSuggestions([]);
      return;
    }

    const multiple = inputWidth / aspectWidth;
    if (Number.isInteger(multiple)) {
      const calculatedHeight = multiple * aspectHeight;
      // Only suggest if both dimensions are >=1
      if (calculatedHeight >= 1) {
        setResolutionSuggestions([{ width: inputWidth, height: calculatedHeight }]);
      } else {
        setResolutionSuggestions([]);
      }
    } else {
      // Calculate nearest lower and upper multiples
      const lowerMultiple = Math.max(1, Math.floor(multiple)); // Ensure lowerMultiple is at least 1
      const upperMultiple = lowerMultiple + 1;

      const lowerResolution = {
        width: lowerMultiple * aspectWidth,
        height: lowerMultiple * aspectHeight,
      };
      const upperResolution = {
        width: upperMultiple * aspectWidth,
        height: upperMultiple * aspectHeight,
      };

      // Filter out any resolutions with dimensions <1
      const validResolutions = [lowerResolution, upperResolution].filter(
        (res) => res.width >= 1 && res.height >= 1
      );

      setResolutionSuggestions(validResolutions);
    }
  } else {
    const inputHeight = parseInt(value);
    if (isNaN(inputHeight) || inputHeight < 1) { // Ensure height is at least 1
      setResolutionSuggestions([]);
      return;
    }

    const multiple = inputHeight / aspectHeight;
    if (Number.isInteger(multiple)) {
      const calculatedWidth = multiple * aspectWidth;
      // Only suggest if both dimensions are >=1
      if (calculatedWidth >= 1) {
        setResolutionSuggestions([{ width: calculatedWidth, height: inputHeight }]);
      } else {
        setResolutionSuggestions([]);
      }
    } else {
      // Calculate nearest lower and upper multiples
      const lowerMultiple = Math.max(1, Math.floor(multiple)); // Ensure lowerMultiple is at least 1
      const upperMultiple = lowerMultiple + 1;

      const lowerResolution = {
        width: lowerMultiple * aspectWidth,
        height: lowerMultiple * aspectHeight,
      };
      const upperResolution = {
        width: upperMultiple * aspectWidth,
        height: upperMultiple * aspectHeight,
      };

      // Filter out any resolutions with dimensions <1
      const validResolutions = [lowerResolution, upperResolution].filter(
        (res) => res.width >= 1 && res.height >= 1
      );

      setResolutionSuggestions(validResolutions);
    }
  }
};

  // Handler to Save Selected Resolution
  const handleSaveResolution = async (selectedResolution) => {
    const { width, height } = selectedResolution;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-image-resolution/${editingResolutionImageId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ width, height }),
        credentials: 'include',
      });
      if (response.ok) {
        // Update the generatedImages state with the new width and height
        setGeneratedImages((prevImages) =>
          prevImages.map((img) =>
            img.id === editingResolutionImageId ? { ...img, width, height } : img
          )
        );
        setEditingResolutionImageId(null);
        setTempResolution({ width: '', height: '' });
        setResolutionSuggestions([]);
      } else {
        const errorData = await response.json();
        alert('Error updating image resolution: ' + errorData.error);
      }
    } catch (error) {
      alert('Network error while updating image resolution.');
    }
  };

  // Handler to Cancel Resolution Editing
  const handleCancelResolution = () => {
    setEditingResolutionImageId(null);
    setTempResolution({ width: '', height: '' });
    setResolutionSuggestions([]);
  };

  return (
    <MidjourneyVerification>
      <PerfectScrollbar>
        <div>
          <ol className="breadcrumb float-xl-end">
            <li className="breadcrumb-item">
              <Link to="/page-option/blank">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/page-option/blank">Page Options</Link>
            </li>
            <li className="breadcrumb-item active">Image Creation</li>
          </ol>
          <h1 className="page-header">
            Image Builder <small>Create images with specific resolutions.</small>
          </h1>
          <Panel>
            <PanelHeader>Image Prompt</PanelHeader>
            <PanelBody style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              <form onSubmit={handleSubmit}>
                {/* Project Name */}
                <div className="mb-3">
                  <label htmlFor="projectName" className="form-label">
                    Project Name (optional): {requestId ? `Request ID: ${requestId}` : ''}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="projectName"
                    name="projectName"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </div>

                {/* Image Prompt */}
                <div className="mb-3">
                  <label htmlFor="prompt" className="form-label">
                    Please provide a prompt for the image:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="prompt"
                    name="prompt"
                    value={promptFormData.prompt}
                    onChange={(e) => setPromptFormData({ ...promptFormData, prompt: e.target.value })}
                  />
                </div>

                {/* Width and Height Inputs */}
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="width" className="form-label me-2">
                    Width:
                  </label>
                  <input
                    className="form-control me-3"
                    type="number"
                    id="width"
                    name="width"
                    placeholder="Width"
                    value={promptFormData.width}
                    onChange={(e) => setPromptFormData({ ...promptFormData, width: e.target.value })}
                    min="1"
                  />
                  <label htmlFor="height" className="form-label me-2">
                    Height:
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="height"
                    name="height"
                    placeholder="Height"
                    value={promptFormData.height}
                    onChange={(e) => setPromptFormData({ ...promptFormData, height: e.target.value })}
                    min="1"
                  />
                </div>

                {/* Dropdown for Image Display Height */}
                <div className="mb-3">
                  <label htmlFor="imageDisplayHeight" className="form-label">
                    Max Image Display Height:
                  </label>
                  <select
                    className="form-select"
                    id="imageDisplayHeight"
                    value={imageDisplayHeight}
                    onChange={(e) => setImageDisplayHeight(parseInt(e.target.value))}
                  >
                    {Array.from({ length: 296 }, (_, i) => (i + 1) * 10 + 40).map((height) => (
                      <option key={height} value={height}>
                        {height}px
                      </option>
                    ))}
                  </select>
                </div>

                {/* Radio Buttons for Scaler Methods */}
                <div className="mb-3">
                  <label htmlFor="scalerMethod" className="form-label">Scaler Method:</label>

                  {/* Option 1: None */}
                  <div>
                    <input
                      type="radio"
                      id="none"
                      name="scalerMethod"
                      value="none"
                      checked={scalerMethod === 'none'}
                      onChange={handleScalerChange}
                    />
                    <label htmlFor="none" className="ms-2">
                      None (Original Resolution)
                    </label>
                  </div>

                  {/* Option 2: Pillow */}
                  <div>
                    <input
                      type="radio"
                      id="pillow"
                      name="scalerMethod"
                      value="pillow"
                      checked={scalerMethod === 'pillow'}
                      onChange={handleScalerChange}
                    />
                    <label htmlFor="pillow" className="ms-2">
                      Pillow (Fastest - Lower Quality)
                    </label>
                  </div>

                  {/* Option 3: OpenCV FSRCNN */}
                  <div>
                    <input
                      type="radio"
                      id="opencv_fsrcnn"
                      name="scalerMethod"
                      value="opencv_fsrcnn"
                      checked={scalerMethod === 'opencv_fsrcnn'}
                      onChange={handleScalerChange}
                    />
                    <label htmlFor="opencv_fsrcnn" className="ms-2">
                      OpenCV FSRCNN (Fast - Good Quality) (max scale 4×)
                      <span style={{ color: 'cyan', marginLeft: '5px' }}>(Recommended)</span>
                    </label>
                  </div>

                  {/* Option 4: OpenCV LapSRN */}
                  <div>
                    <input
                      type="radio"
                      id="opencv_lapsrn"
                      name="scalerMethod"
                      value="opencv_lapsrn"
                      checked={scalerMethod === 'opencv_lapsrn'}
                      onChange={handleScalerChange}
                    />
                    <label htmlFor="opencv_lapsrn" className="ms-2">
                      OpenCV LapSRN (Moderate Speed - High Quality) (max scale 8×)
                    </label>
                  </div>

                  {/* Option 5: OpenCV EDSR (Not Recommended) */}
                  <div>
                    <input
                      type="radio"
                      id="opencv_edsr"
                      name="scalerMethod"
                      value="opencv_edsr"
                      checked={scalerMethod === 'opencv_edsr'}
                      onChange={handleScalerChange}
                    />
                    <label htmlFor="opencv_edsr" className="ms-2">
                      OpenCV EDSR (Slowest - Highest Quality) (max scale 4×)
                      <span style={{ color: 'orange', marginLeft: '5px' }}>(Not Recommended)</span>
                    </label>
                  </div>
                </div>

                {/* Mirror All and Rotate 180 Buttons */}
                <div className="mb-3 d-flex justify-content-start mt-3">
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-3"
                    style={{
                      backgroundColor: mirrorAll ? 'blue' : '',
                      color: mirrorAll ? 'white' : '',
                    }}
                    onClick={toggleMirrorAll}
                  >
                    <i className="fas fa-arrows-alt-h"></i> Mirror All
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    style={{
                      backgroundColor: rotateAll ? 'blue' : '',
                      color: rotateAll ? 'white' : '',
                    }}
                    onClick={toggleRotateAll}
                  >
                    <i className="fas fa-sync-alt"></i> Rotate 180
                  </button>
                </div>

                {/* Action Buttons */}
                <button
                  type="submit"
                  className="btn btn-outline-inverse active me-3"
                  disabled={loading || generatingRequestId}
                >
                  {loading ? <i className="fas fa-cog fa-spin"></i> : 'Build'}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleNewProject}
                >
                  New Project
                </button>
                {generatedImages.length > 0 && (
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleDownloadAllImages}
                    disabled={generatedImages.length === 0 || downloading}
                  >
                    {downloading ? <i className="fas fa-cog fa-spin"></i> : 'Download All Images'}
                  </button>
                )}
              </form>

              {/* Generated Images Display */}
              {generatedImages.map((image, index) => (
                <div
                  key={image.id}
                  className="image-container"
                  style={{ position: 'relative', display: 'inline-block', marginRight: '10px', marginBottom: '10px' }}
                >
                <img
                  src={image.url}
                  alt={`Generated ${index + 1}`}
                  style={{
                    maxHeight: `${imageDisplayHeight}px`,
                    maxWidth: '100%', // Ensures the image doesn't exceed the container's width
                    height: 'auto', // Maintains aspect ratio
                    transform: `${image.isMirrored ? 'scaleX(-1)' : ''} ${image.isFlipped ? 'scaleY(-1)' : ''}`,
                    display: 'block', // Removes the small space below images
                  }}
                />


                  {/* Download Button */}
                  <button
                      className="download-icon"
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: 'rgba(0,0,0,0.5)',
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                    onClick={() => handleDownloadSingleImage(image.id)}
                  >
                    <i className="fas fa-download"></i>
                  </button>

                  {/* Mirror Button */}
                  <button
                    className={`mirror-icon ${image.isMirrored ? 'btn-primary' : 'btn-secondary'}`}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '45px',
                      background: image.isMirrored ? 'blue' : 'gray', // Conditional color based on mirroring state
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                    onClick={() => toggleMirror(image.id)}
                  >
                    <i className="fas fa-arrows-alt-h"></i>
                  </button>

                  {/* Flip Button */}
                  <button
                    className={`flip-icon ${image.isFlipped ? 'btn-primary' : 'btn-secondary'}`}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '85px',
                      background: image.isFlipped ? 'blue' : 'gray', // Conditional color based on flipping state
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer',
                      padding: '5px',
                      borderRadius: '50%',
                    }}
                    onClick={() => toggleFlip(image.id)}
                  >
                    <i className="fas fa-sync-alt"></i>
                  </button>

                  {/* Resolution Button or Editing Fields */}
                  {editingResolutionImageId === image.id ? (
                    <div
                      className="resolution-edit"
                      ref={resolutionEditRef}
                      style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(255,255,255,0.95)',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '10px',
                        zIndex: 10,
                        width: '250px',
                        color: 'black'
                      }}
                    >
                      {/* Current Aspect Ratio */}
                      <div className="mb-2">
                        <label className="form-label">Aspect Ratio:</label>
                        <span>{image.aspectRatio}</span>
                      </div>

                      {/* Width Input */}
                      <div className="mb-2">
                        <label className="form-label">Width:</label>
                        <input
                          type="number"
                          name="width"
                          value={tempResolution.width}
                          onChange={(e) => handleResolutionInputChange(e, true)}
                          className="form-control"
                          min="1"
                        />
                      </div>

                      {/* Height Input */}
                      <div className="mb-2">
                        <label className="form-label">Height:</label>
                        <input
                          type="number"
                          name="height"
                          value={tempResolution.height}
                          onChange={(e) => handleResolutionInputChange(e, false)}
                          className="form-control"
                          min="1"
                        />
                      </div>

                      {/* Resolution Suggestions */}
                      {resolutionSuggestions.length > 0 && (
                        <div className="mb-2">
                          <label className="form-label">Select Resolution:</label>
                          <div>
                            {resolutionSuggestions.map((res, idx) => (
                              <div key={idx} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`resolutionOption-${image.id}`}
                                  id={`resolutionOption-${image.id}-${idx}`}
                                  value={`${res.width}x${res.height}`}
                                  onChange={() => handleSaveResolution(res)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`resolutionOption-${image.id}-${idx}`}
                                >
                                  {res.width}x{res.height}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Action Buttons */}
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary me-2"
                          onClick={handleCancelResolution}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      className="resolution-button"
                      style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(0,0,0,0.5)',
                        border: 'none',
                        color: 'white',
                        cursor: 'pointer',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                      onClick={() => handleResolutionClick(image)}
                    >
                      {image.width}x{image.height}
                    </button>
                  )}
                </div>
              ))}

              {/* Back to Projects Button */}
              <div className="mt-4">
                <Link to="/projects" className="btn btn-secondary">
                  Back to My Projects
                </Link>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </PerfectScrollbar>
      <div id="footer" className="app-footer m-0">
        &copy; {currentYear} MerchPro All Rights Reserved
      </div>
    </MidjourneyVerification>
  );
}

export default ImageCreation;
