import React, { useContext, useEffect } from 'react';
import { AppSettings } from "../../../config/app-settings.js";

function DropdownProfile() {
    const context = useContext(AppSettings);

    // Fetch user details on component mount
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verify_login`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    context.setUser(data.user); // Update context with user data
                } else if (response.status === 401) {
                    console.warn('User is not logged in, handling gracefully.');
                } else {
                    console.error('Failed to verify user:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []); // Only run once when the component mounts

    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
                method: 'POST',
                credentials: 'include',
            });

            if (response.ok) {
                context.setUser(null);
                window.location.href = '/login';
            } else {
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <div className="navbar-item navbar-user dropdown">
            <a href="#/" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
                <img src="/assets/img/user/mp_user_1.png" alt="" />
                <span>
                    <span className="d-none d-md-inline">{context.user?.firstName || 'User'}</span>
                    <b className="caret"></b>
                </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end me-1">
                <a href="#/" className="dropdown-item">Edit Profile</a>
                <a href="#/" className="dropdown-item d-flex align-items-center">
                    Inbox
                    <span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
                </a>
                <a href="#/" className="dropdown-item">Calendar</a>
                <a href="#/" className="dropdown-item">Settings</a>
                <div className="dropdown-divider"></div>
                <a href="#/" className="dropdown-item" onClick={handleLogout}>Log Out</a>
            </div>
        </div>
    );
}

export default DropdownProfile;
